<template>
  <section class="queues">
   <div id="queues">
     <vs-row vs-w="8" class="flex title">
      <h1>
        {{ lang.queues.title[languageSelected] }}
      </h1>
    </vs-row>
    <EmptyTable
        margin-top="20px"
        v-if="(!queues ||queues.length === 0) && isAuthAux('konecta.queues.add')" 
        :text="lang.queues.table.empty[languageSelected]"
        :buttonText="lang.queues.table.addQueue[languageSelected]"
        :button-action="() => this.SET_QUEUE()"
        
    />
    <QueuesList v-if="queues &&queues.length > 0 && !selectedQueue && isAuthAux('konecta.queues.list')" />
    <QueueDetail v-if="selectedQueue"/>
    <!-- <TestCaseDetail v-if="selectedTestCase" :openAddTest="openAddTest" :openAddTestCase="openAddTestCase" :excecuteTestGroup="excecuteTestGroup" /> -->
    <!--  -->
  <!-- END POPUP EXECUTION -->
  </div>

  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// import NewFormGroup from '../bot-maker/add-bot/Components/NewFormGroup.vue'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'queues',
  mixins: [staffManagementMixin],
  components: {
    EmptyTable: () => import('../components/EmptyTable.vue'),
    // NewFormGroup,
    QueueDetail: () => import('./QueueDetail.vue'),
    QueuesList: () => import('./QueuesList.vue'),
  },
  props: {
  },
  data() {
    return {
      activePopup: false,
      activeCasePopup: false,
      activeExecutePopup: false,
      createTestGroup: {
        name: '',
        description: '',
        id: null,
      },
      createTestCase: {
				name: '',
				description: '',
        id: null,
			},
      listKey: new Date().getTime(),
      execution: {
        mails: [],
        testGroup: '',
        mail: '',
        previousMail: null,
        editingMailIndex: null,
      },
      validations: {
        emptyMail: false,
        emptyMailList: false,
        mailWithInstance: '',
        duplicateMail: false,
        createTestCase: {
					nameError: false,
					descriptionError: false,
				},
        createTestGroup: {
          nameError: false,
          descriptionError: false,
        }
      },
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('queues', ['queues', 'selectedQueue']),
    mailHasError() {
      return this.validations.emptyMail || this.validations.duplicateMail || this.validations.emptyMailList
    },
    mailErrorMessage() {
      if (this.validations.emptyMail) {
        return this.lang.botMaker.testGroups.view.errors.execution.empty[this.languageSelected]
      }

      if (this.validations.emptyMailList) {
        return this.lang.botMaker.testGroups.view.errors.execution.emptyList[this.languageSelected]
      }

      return this.validations.mailWithInstance
        ? this.lang.botMaker.testGroups.view.errors.execution.duplicate[this.languageSelected]
        : ''
    },
    isPromptActive: {
      get() {
        return this.activeExecutePopup;
      },
      set() {
        return true;
      }
    }
  },
  watch: {
    testGroups() {
    },
  },
  methods: {
    ...mapActions('queues', ['GET_QUEUES', 'UNSET_QUEUE', 'SET_QUEUE']),
    // }
   
  },
  async mounted() {
    this.$vs.loading()
    try {
        await this.GET_QUEUES();
        const queueId = this.$route.query.queueId;
        if (queueId === undefined || queueId === null) {
          this.UNSET_QUEUE();
        } else {
          this.SET_QUEUE(queueId);
        }
    } catch (ex) {
        this.$vs.notify({
            title: 'Error',
            text: this.lang.queues.getError[this.languageSelected],
            color: 'danger'
        })
    }
    this.$vs.loading.close()
  }
}
</script>

<style lang="scss">
#test-group {
  .title {
    margin-bottom: 20px;
  }
}
.intent-prompt-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .intent-examples {
    display: flex;
    align-items: center;
  }
  .intent-input {
    width: 50%;
    display: flex;
    align-items: center;
  }
  .add-intent-btn {
    margin-left: 5px;
  }
  .intent-example {
    display: flex;
    align-items: center;
  }
  .clear-intent-btn {
    margin-right: 5px;
  }
  .intent-examples-list {
    max-height: 400px;
    overflow-y: auto;
  }
  .con-vs-dialog .vs-dialog footer button:not(:first-of-type) {
    background: rgba(var(--vs-danger), 1) !important;
  }
  .con-vs-dialog .vs-dialog footer {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: flex-end;
  }
  .con-vs-dialog .vs-dialog footer .vs-button {
    margin-left: 0.5rem;
  }
  .con-vs-dialog .vs-dialog {
    max-width: 650px;
  }
  .span-text-validation-danger {
    height: unset !important;
  }
.error-message {
  display: block;
  height: 19px;
  color: rgba(var(--vs-danger), 1);
  font-size: 0.65rem;
}
</style>